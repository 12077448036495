

























import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  directives: { TestLocator },
})
export default class StarRatingSelect extends Vue {
  @Prop({ type: Number, default: 5 })
  maxRating!: number;

  @Model('change', { type: Number, default: 0 }) readonly rating!: number;

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;
}
