






import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Spinner from '@/themes/v1/components/Spinner.vue';

@Component({
  components: {
    Spinner,
  },
})
export default class WarningPopover extends Vue {
  @Prop({ type: Boolean, default: false })
  show!: boolean;

  @Prop({ type: Boolean, default: false })
  closeOnClick!: boolean;

  @Prop({ type: Number, default: 0 })
  delay!: number;

  private closingTimer: number | null = null;

  @Watch('show')
  onChangeShow(value: boolean, oldValue: boolean) {
    if (value === true && value != oldValue && this.delay) {
      this.closingTimer = window.setTimeout(() => this.close(), this.delay);
    }
  }

  @Emit()
  close() {
    this.closingTimer = null;
  }

  onClick() {
    if (this.closeOnClick === true) {
      if (this.closingTimer != null) {
        window.clearTimeout(this.closingTimer);
      }
      this.close();
    }
  }
}
