



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { FeedbackOptions } from '@/modules/sts/feedback/feedback-options.model';
import { TicketChatFeedback } from '@/modules/sts/chat';
import YesNoSelect from '@/themes/v1/components/YesNoSelect.vue';
import StarRatingSelect from '@/themes/v1/components/StarRatingSelect.vue';
@Component({
  components: { StarRatingSelect, YesNoSelect },
})
export default class FeedbackMessage extends Vue {
  @Prop({ type: Object, required: true })
  options!: FeedbackOptions;

  @Prop({ type: Object, required: true })
  feedback!: TicketChatFeedback;
}
