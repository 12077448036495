































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import debounce from 'lodash/debounce';
import ControlLabel from '../../../../components/ControlLabel.vue';
import ControlError from '@/themes/v1/components/ControlError.vue';
import InlineCtrlWithLabel from '@/themes/v1/components/InlineCtrlWithLabel.vue';
import AttachInput from '@/themes/v1/components/AttachInput.vue';
import FileView from '@/themes/v1/components/FileView.vue';
import env from '@/env';
import { validateFile } from '@/themes/v1/views/sts/validators';
import { FilesStatuses, ReplyForm } from './ReplyInput.vue';
import GrayBox from './GrayBox.vue';
import WarningPopover from './WarningPopover.vue';
import { emitBlockResized } from '@/themes/v1/components/FixedPanel.vue';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  components: {
    FileView,
    AttachInput,
    InlineCtrlWithLabel,
    ControlError,
    ControlLabel,
    ValidationObserver,
    ValidationProvider,
    GrayBox,
    WarningPopover,
  },
  directives: { TestLocator },
})
export default class ReplyInputCompact extends Vue {
  @Prop({ type: Boolean, default: false })
  processing!: boolean;

  @Prop({ type: Object, default: () => ({}) })
  filesStatuses!: FilesStatuses;

  showAttachments = false;
  showFileError = false;
  showFileSizeError = false;

  $refs!: {
    panel: HTMLDivElement;
    replyForm: InstanceType<typeof ValidationObserver>;
    messageInput: any;
  };

  get isValid() {
    return (
      (this.form.message &&
        this.form.message.length >= 3 &&
        this.form.message.length <= 3000) ||
      this.form.files.length
    );
  }

  private form: ReplyForm = { files: [], message: '' };
  private messageInputHeight: number | null = null;

  async sendReply() {
    if (this.isValid) {
      this.onSubmit();
    }
  }

  @Emit('submit')
  private onSubmit() {
    return this.form;
  }

  closeFileError() {
    this.showFileSizeError = false;
    this.showFileError = false;
  }

  toggleShowAttachments() {
    this.showAttachments = !this.showAttachments;
    emitBlockResized(this.$refs.panel);
  }

  onMessageInput = debounce(() => {
    const height = this.$refs.messageInput.$el.clientHeight;
    if (this.messageInputHeight !== height) {
      this.messageInputHeight = height;
      emitBlockResized(this.$refs.messageInput?.$el);
    }
  }, 50);

  private async addFile(files: File[]) {
    let hasError = false;
    files.forEach((file) => {
      const valid = validateFile(file);
      if (valid) {
        if (this.filesSizeSummary + file.size > env.maxAttachment) {
          this.showFileSizeError = true;
        } else if (!this.hasFile(file)) {
          this.form.files = [...this.form.files, file];
          this.showFileSizeError = false;
          emitBlockResized(this.$refs.panel);
        }
      } else {
        hasError = true;
      }
    });

    this.showFileError = hasError;
  }

  private hasFile(file: File) {
    return this.form.files.find(
      (x: File) =>
        x.name === file.name &&
        x.size === file.size &&
        x.lastModified === file.lastModified
    );
  }

  get filesSizeSummary() {
    return this.form.files.reduce((sum, x) => x.size + sum, 0);
  }

  get filesSizeSummaryString() {
    const mb = (this.filesSizeSummary / 1024 / 1024).toFixed(2);
    return `${mb} ${this.$t('common.files.mb')}`;
  }

  private removeFile(file: File) {
    this.showFileError = false;
    this.showFileSizeError = false;
    this.form.files = this.form.files.filter((f) => f !== file);
    if (this.form.files.length === 0) {
      this.showAttachments = false;
    }
    emitBlockResized(this.$refs.panel);
  }

  clear() {
    this.form = {
      files: [],
      message: '',
    };
    this.$refs.replyForm.reset();
    emitBlockResized(this.$refs.panel, true);
  }
}
