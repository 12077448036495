








































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { OnDestroyed, OnMounted, WithMeta } from '@/core/vue.types';
import {
  CloseReason,
  TicketChatFeedback,
  TicketChatMessage,
  TicketView,
} from '@/modules/sts/chat';
import { humanizingTime } from '@/core/utils';
import ScrollBox from '@/themes/v1/components/ScrollBox.vue';
import Message from '@/themes/v1/views/sts/tickets/components/Message.vue';
import ControlLabel from '@/themes/v1/components/ControlLabel.vue';
import InlineCtrlWithLabel from '@/themes/v1/components/InlineCtrlWithLabel.vue';
import AttachInput from '@/themes/v1/components/AttachInput.vue';
import DiscussionDeletePopup from '@/themes/v1/views/sts/tickets/components/DiscussionDeletePopup.vue';
import { getModule } from 'vuex-module-decorators';
import { PlayerStore } from '@/themes/v1/stores/player.store';
import FileView from '@/themes/v1/components/FileView.vue';
import ControlError from '@/themes/v1/components/ControlError.vue';
import { MetaInfo } from 'vue-meta';
import { FeedbackService } from '@/modules/sts/feedback/feedback.service';
import { FeedbackOptions } from '@/modules/sts/feedback/feedback-options.model';
import DeleteInput from './components/DeleteInput.vue';
import ReplyInput, {
  FilesStatuses,
  ReplyForm,
} from './components/ReplyInput.vue';
import ReplyInputCompact from './components/ReplyInputCompact.vue';
import FeedbackInput from './components/FeedbackInput.vue';
import { Feedback } from '@/modules/sts/feedback/send-feedback.model';
import orderBy from 'lodash/orderBy';
import throttle from 'lodash/throttle';
import last from 'lodash/last';
import FeedbackMessage from '@/themes/v1/views/sts/tickets/components/FeedbackMessage.vue';
import { ChatService } from '@/modules/sts/chat/chat.service';
import { replaceWithLangAndPlatform } from '@/themes/v1/fns/router';
import Spinner from '@/themes/v1/components/Spinner.vue';
import { TicketStatus } from '@/modules/sts/common.models';
import FixedPanel from '@/themes/v1/components/FixedPanel.vue';
import GrayBox from './components/GrayBox.vue';
import { Devices } from '@/themes/v1/stores/ui.store';
import smoothScroll from '@/themes/v1/fns/smooth-scroll';
import TestLocator from '@/core/test-utils/test-locator';
import ClickOutside from 'vue-click-outside';

// eslint-disable-next-line @typescript-eslint/no-var-requires

interface ChatItem {
  type: 'message' | 'feedback';
  timestamp: string;
  data: TicketChatMessage | TicketChatFeedback;
}

@Component({
  components: {
    FeedbackMessage,
    FeedbackInput,
    ReplyInput,
    ReplyInputCompact,
    DeleteInput,
    ControlError,
    FileView,
    DiscussionDeletePopup,
    AttachInput,
    InlineCtrlWithLabel,
    ControlLabel,
    Message,
    ScrollBox,
    Spinner,
    FixedPanel,
    GrayBox,
  },
  directives: {
    ClickOutside,
    TestLocator,
  },
})
export default class TicketDiscussion
  extends Vue
  implements OnDestroyed, WithMeta, OnMounted {
  @Inject()
  chatService!: ChatService;

  @Inject()
  feedbackService!: FeedbackService;

  loading = true;
  error = false;
  processing = false;
  removing = false;
  sendingFeedback = false;
  ticket: TicketView | null = null;
  feedbackOptions: FeedbackOptions | null = null;
  filesStatuses: FilesStatuses = {};
  showInput = false;
  feedbackSent = false;
  showControls = true;

  updateTimer: number | null = null;
  showDeletePopup = false;
  playerStore = getModule(PlayerStore, this.$store);

  $refs!: {
    replyForm: InstanceType<typeof ReplyInput>;
    scrollBox: InstanceType<typeof ScrollBox>;
  };

  get ticketNumber() {
    return this.$route.params.ticket;
  }

  get playerId() {
    return this.playerStore.playerId;
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.tickets.ticket-number', {
        number: this.ticketNumber,
      }) as string,
    };
  }

  async load() {
    try {
      this.loading = true;
      const [ticket, options] = await humanizingTime(
        Promise.all([
          this.chatService.getTicket(this.playerId, this.ticketNumber),
          this.feedbackService.getOptions(this.$i18n.locale),
        ])
      );
      this.ticket = ticket;
      this.feedbackOptions = options;
      this.runChatAutoUpdate();
      this.error = false;
      if (
        !this.ticket.isClosed &&
        !this.ticket.allowFeedback &&
        this.ticket.status === TicketStatus.Resolved
      ) {
        this.showControls = false;
      }
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    if (window.visualViewport && window.visualViewport.addEventListener) {
      window.visualViewport.addEventListener('resize', () =>
        this.onVisualViewportResize()
      );
    }
    this.scrollToBottom();
    await this.load();
  }

  destroyed(): void {
    if (window.visualViewport && window.visualViewport.removeEventListener) {
      window.visualViewport.removeEventListener('resize', () =>
        this.onVisualViewportResize()
      );
    }
    this.stopChatAutoUpdate();
  }

  private onVisualViewportResize() {
    this.scrollToBottom();
  }

  private runChatAutoUpdate() {
    if (process.server) return;
    this.stopChatAutoUpdate();
    this.updateTimer = setInterval(async () => {
      if (this.ticketNumber == null) {
        this.stopChatAutoUpdate();
        return;
      }
      if (this.ticket == null) {
        return;
      }
      if (this.ticket.allowFeedback) return;
      const ticket: TicketView = await this.chatService.getTicket(
        this.playerId,
        this.ticketNumber
      );
      if (
        ticket.feedbacks.length !== this.ticket.feedbacks.length ||
        ticket.messages.length !== this.ticket.messages.length ||
        ticket.allowFeedback !== this.ticket.allowFeedback ||
        ticket.isClosed !== this.ticket.isClosed ||
        ticket.status !== this.ticket.status
      ) {
        this.ticket = ticket;
        this.scrollToBottom();
      }
    }, 30000);
  }

  private stopChatAutoUpdate() {
    if (this.updateTimer != null) {
      clearInterval(this.updateTimer);
    }
  }

  async reply(form: ReplyForm) {
    try {
      this.processing = true;
      const files = await this.uploadFiles(form.files);
      await this.chatService.reply(
        this.playerId,
        this.ticketNumber,
        form.message,
        files.files.map((x) => x.id)
      );
      this.ticket = await this.chatService.getTicket(
        this.playerId,
        this.ticketNumber
      );
      this.showInput = false;
      this.$refs.replyForm.clear();
      this.scrollToBottom();
    } catch (e) {
      console.error(e);
    } finally {
      this.filesStatuses = {};
      this.processing = false;
    }
  }

  async uploadFiles(files: File[]) {
    return await this.chatService.upload(
      files,
      throttle((progress) => {
        files.map(async (file, inx) =>
          Vue.set(this.filesStatuses, inx, progress)
        );
      }, 300)
    );
  }

  async remove() {
    if (this.removing === true) {
      return;
    }
    try {
      this.removing = true;
      this.stopChatAutoUpdate();
      await this.chatService.remove(this.playerId, [this.ticketNumber]);
      await replaceWithLangAndPlatform(this.$router, 'tickets');
    } catch (e) {
      this.removing = false;
      this.showDeletePopup = false;
    }
  }

  async savePartialFeedback(feedback: Feedback) {
    try {
      await this.chatService.feedback(this.ticketNumber, feedback);
    } catch (e) {
      console.error(e);
    }
  }

  async sendFeedback(feedback: Feedback) {
    try {
      this.sendingFeedback = true;
      await this.chatService.feedback(this.ticketNumber, feedback);
      this.ticket = await this.chatService.getTicket(
        this.playerId,
        this.ticketNumber
      );
      this.showControls = false;
    } finally {
      this.sendingFeedback = false;
    }
  }

  get chatItems(): Array<ChatItem> {
    if (!this.ticket) return [];
    const all = [
      ...this.ticket.messages.map(
        (x, index, arr) =>
          ({
            type: 'message',
            data: x,
            timestamp: x.timestamp,
            isLastMessage: index === arr.length - 1,
          } as ChatItem)
      ),
      ...this.ticket.feedbacks.map(
        (x) =>
          ({
            type: 'feedback',
            data: x,
            timestamp: x.timestamp,
          } as ChatItem)
      ),
    ];

    return orderBy(all, ['timestamp'], ['asc']);
  }

  get ticketWasExpired() {
    return (
      this.ticket != null &&
      this.ticket.isClosed &&
      this.ticket.closeReason === CloseReason.TimeExpired
    );
  }

  async rateMessage(message: TicketChatMessage, isHelpful: boolean) {
    try {
      this.processing = true;
      await this.chatService.rateMessage(
        this.ticketNumber,
        message.id,
        isHelpful
      );
      this.ticket = await this.chatService.getTicket(
        this.playerId,
        this.ticketNumber
      );
      if (this.ticket.isClosed) {
        this.showInput = false;
      }
      this.processing = false;
    } catch {
      this.processing = false;
    }
  }

  private scrollToBottom() {
    if (this.$bps(Devices.xs)) {
      smoothScroll(document.body.scrollHeight);
    } else {
      this.$refs.scrollBox?.scrollTo({ y: '100%' }, 300);
    }
  }
}
