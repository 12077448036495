import copy from 'copy-text-to-clipboard';

function addButton(link: HTMLAnchorElement, callback: (link: string) => void) {
  const btn = document.createElement('button');
  btn.classList.add('button', 'button--icon', 'icon-copy');
  btn.addEventListener('click', () => {
    const src = link.dataset.href || link.href;
    copy(src);
    callback(src);
  })
  link.after(btn);
}

function addWordBreaks(link: HTMLAnchorElement) {
  link.innerHTML = link.innerHTML.replace(/(\/\/)|[/-]/g, '$&<wbr>');
}

export default {
  inserted: function (el: HTMLElement, binding) {
    if (binding.arg == 'disabled') {
      return;
    }
    const links = el.getElementsByTagName('A') as HTMLCollectionOf<HTMLAnchorElement>;
    for (const link of links) {
      const callback = (binding.value != null && typeof(binding.value) === 'function')
        ? binding.value
        : () => {};

      addButton(link, callback);
      addWordBreaks(link);
    }
  },
};
