
























































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ControlLabel from '../../../../components/ControlLabel.vue';
import ControlError from '@/themes/v1/components/ControlError.vue';
import InlineCtrlWithLabel from '@/themes/v1/components/InlineCtrlWithLabel.vue';
import AttachInput from '@/themes/v1/components/AttachInput.vue';
import FileView from '@/themes/v1/components/FileView.vue';
import env from '@/env';
import { validateFile } from '@/themes/v1/views/sts/validators';
import TestLocator from '@/core/test-utils/test-locator';

export interface FilesStatuses {
  [index: number]: number;
}

export interface ReplyForm {
  message: string;
  files: File[];
}

@Component({
  components: {
    FileView,
    AttachInput,
    InlineCtrlWithLabel,
    ControlError,
    ControlLabel,
    ValidationObserver,
    ValidationProvider,
  },
  directives: { TestLocator },
})
export default class ReplyInput extends Vue {
  @Prop({ type: Boolean, default: false })
  processing!: boolean;

  @Prop({ type: Object, default: () => ({}) })
  filesStatuses!: FilesStatuses;

  validated = false;

  private form: ReplyForm = { files: [], message: '' };
  private showFileError = false;
  private showFileSizeError = false;

  async validateAndSubmit(fn: any) {
    this.validated = true;
    if (!this.form.message && this.form.files.length) {
      this.onSubmit();
    } else {
      await fn(() => this.onSubmit());
    }
  }

  @Emit('submit')
  private onSubmit() {
    return this.form;
  }

  $refs!: {
    replyForm: InstanceType<typeof ValidationObserver>;
  };

  private async addFile(files: File[]) {
    let hasError = false;
    files.forEach((file) => {
      const valid = validateFile(file);
      if (valid) {
        if (this.currentFileSize + file.size > env.maxAttachment) {
          this.showFileSizeError = true;
        } else if (!this.hasFile(file)) {
          this.form.files = [...this.form.files, file];
          this.showFileSizeError = false;
        }
      } else {
        hasError = true;
      }
    });

    this.showFileError = hasError;
  }

  private hasFile(file: File) {
    return this.form.files.find(
      (x: File) =>
        x.name === file.name &&
        x.size === file.size &&
        x.lastModified === file.lastModified
    );
  }

  get currentFileSize() {
    return this.form.files.reduce((sum, x) => x.size + sum, 0);
  }

  private removeFile(file: File) {
    this.showFileError = false;
    this.showFileSizeError = false;
    this.form.files = this.form.files.filter((f) => f !== file);
  }

  clear() {
    this.form = {
      files: [],
      message: '',
    };
    this.$refs.replyForm.reset();
  }
}
