var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"replyForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var touched = ref.touched;
return [_c('ValidationProvider',{attrs:{"tag":"div","rules":"required|min:3|max:3000","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var failedRules = ref.failedRules;
return [_c('ControlLabel',[_c('span',{attrs:{"slot":"right"},slot:"right"},[_vm._v(_vm._s(_vm.$t('forms.labels.messageLenght', { length: _vm.$n(_vm.form.message.length, 'decimal'), }))+" ")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"},{name:"test-locator",rawName:"v-test-locator",value:('input-message'),expression:"'input-message'"}],staticClass:"input",class:{ invalid: invalid && touched },attrs:{"disabled":_vm.processing,"rows":"5"},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}}),((touched || _vm.validated) && invalid)?[(
          (failedRules.required || failedRules.min) && _vm.form.files.length === 0
        )?_c('ControlError',[_vm._v(" "+_vm._s(_vm.$t('forms.errors.message-or-file-require'))+" ")]):_vm._e(),(failedRules.max)?_c('ControlError',[_vm._v(" "+_vm._s(_vm.$t('forms.errors.message.maxLength'))+" ")]):_vm._e()]:_vm._e()]}}],null,true)}),_c('div',{staticClass:"ticket-chat-input__action"},[_c('InlineCtrlWithLabel',{staticClass:"ticket-chat-input__attach-files"},[_c('AttachInput',{directives:[{name:"test-locator",rawName:"v-test-locator",value:('input-file'),expression:"'input-file'"}],attrs:{"slot":"ctrl","disabled":_vm.processing,"multiple":""},on:{"change":_vm.addFile},slot:"ctrl"}),_c('span',{class:{ 'control-error': _vm.showFileSizeError },attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('forms.labels.fileLength'))+" ")])],1),_c('button',{directives:[{name:"test-locator",rawName:"v-test-locator",value:('btn-send-reply'),expression:"'btn-send-reply'"}],staticClass:"button button--large",attrs:{"type":"button","disabled":_vm.processing},on:{"click":function($event){return _vm.validateAndSubmit(handleSubmit)}}},[_vm._v(" "+_vm._s(_vm.$t('phrases.send'))+" ")])],1),(_vm.showFileError)?_c('ControlError',{staticClass:"m-b--lv4"},[_vm._v(" "+_vm._s(_vm.$t('forms.errors.filesFormat'))+" ")]):_vm._e(),(_vm.form.files != null && _vm.form.files.length > 0)?_c('div',{staticClass:"ticket-chat-input__files"},_vm._l((_vm.form.files),function(file,n){return _c('div',{key:n,staticClass:"ticket-chat-input__file"},[_c('FileView',{attrs:{"file":file,"loading":_vm.processing,"progress":_vm.filesStatuses[n]},on:{"remove":function($event){return _vm.removeFile(file)}}})],1)}),0):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }