



























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { chatService, PlayerId, TicketNumber } from '@/modules/sts/chat';
import Avatar from '@/themes/v1/views/sts/tickets/components/Avatar.vue';
import BgImage from '@/themes/v1/components/BgImage.vue';
import { UploadedFile } from '@/modules/sts/common.models';
import ImageViewer from '@/themes/v1/components/ImageViewer.vue';

@Component({
  components: { ImageViewer, BgImage, Avatar },
})
export default class MessageAttachments extends Vue {
  @Prop({ type: Array, default: () => [] })
  attachments!: UploadedFile[];

  @Prop({ type: String, required: true })
  playerId!: PlayerId;

  @Prop({ type: String, required: true })
  ticketNumber!: TicketNumber;

  @Emit()
  select(file: UploadedFile) {
    return file;
  }

  get files() {
    if (this.attachments == null) {
      return [];
    }
    return this.attachments.sort((a, b) =>
      a.isImage === b.isImage ? 0 : a.isImage ? -1 : 1
    );
  }

  getPreviewUrl(file: UploadedFile) {
    return chatService.getFilePreviewUrl(
      this.playerId,
      this.ticketNumber,
      file
    );
  }

  getFileExt(file: UploadedFile) {
    const [ext, name] = file.name.split('.').reverse();
    return name ? String(ext).toUpperCase() : '';
  }

  onClick(file: UploadedFile) {
    if (!file.isDeleted) {
      this.select(file);
    }
  }
}
