/* eslint-disable no-useless-escape */
enum NodeTypes {
  ELEMENT = 1,
  TEXT = 3,
}

const urlRegex = /[\w.-]+(?:\.[\w\.-]+)+[\w\-_~:\/?#[\]@!\$&'\(\)\*\+,;=%]+/gm;
const httpRegex = /https?:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-_~:\/?#[\]@!\$&'\(\)\*\+,;=%]+/gm;
const wwwRegex = /(\s)(www\.[\w.-]+(?:\.[\w\.-]+)+[\w\-_~:\/?#[\]@!\$&'\(\)\*\+,;=%]+)/gm;
const emailRegex = /[\wа-яА-Я.-]+@[\wа-яА-Я.-]+\.[\wа-яА-Я-]+/gm;

function wrapLinks(node: Node): void {
  if (node.textContent == null) {
    return;
  }
  const text = node.textContent
    .replace(httpRegex, '<a href="$&">$&</a>')
    .replace(wwwRegex, '$1<a href="http://$2">$2</a>')
    .replace(emailRegex, '<a href="mailto:$&">$&</a>');
  const temporary = document.createElement('div');
  temporary.insertAdjacentHTML('afterbegin', text);
  const parent = node.parentElement;
  while (temporary.childNodes.length > 0) {
    parent?.insertBefore(temporary.childNodes[0], node);
  }
  parent?.removeChild(node);
}

function checkNode(node: Node) {
  if (node.nodeType === NodeTypes.ELEMENT) {
    if (node.nodeName === 'A') {
      return;
    } else if (node.hasChildNodes()) {
      node.childNodes.forEach((node) => checkNode(node));
    }
  } else if (node.nodeType === NodeTypes.TEXT && node.textContent != null) {
    const content = node.textContent.toString();
    if (urlRegex.test(content)) {
      wrapLinks(node);
    }
    urlRegex.lastIndex = 0;
  }
}

export default (input: string): string => {
  try {
    const element = document.createElement('div');
    element.innerHTML = input;
    checkNode(element);
    return element.innerHTML;
  } catch (exc) {
    console.log(exc);
    return input;
  }
}

export const isEmail = (input: string): boolean => {
  return (/^[\wа-яА-Я.-]+@[\wа-яА-Я.-]+\.[\wа-яА-Я-]+$/gm).test(input);
}