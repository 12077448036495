import { render, staticRenderFns } from "./StarRatingSelect.vue?vue&type=template&id=91a609de&scoped=true&"
import script from "./StarRatingSelect.vue?vue&type=script&lang=ts&"
export * from "./StarRatingSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91a609de",
  null
  
)

export default component.exports