import { render, staticRenderFns } from "./MessageAttachments.vue?vue&type=template&id=7a9d0a4a&scoped=true&"
import script from "./MessageAttachments.vue?vue&type=script&lang=ts&"
export * from "./MessageAttachments.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a9d0a4a",
  null
  
)

export default component.exports