export default {
  inserted: function (el, binding) {
    if (binding.value != null && typeof binding.value === 'function') {
      el.addEventListener('click', (clickEvent) => {
        const target = clickEvent.target as HTMLElement;
        if (target.tagName === 'A') {
          clickEvent.preventDefault();
          binding.value(target);
        }
      });
    }
  },
};
