























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  directives: { TestLocator },
})
export default class DeleteInput extends Vue {
  @Prop({ required: true, type: Boolean })
  ticketWasExpired!: boolean;

  @Emit('delete')
  onDelete() {}
}
