

















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { FeedbackOptions } from '@/modules/sts/feedback/feedback-options.model';
import Stepper from '@/themes/v1/components/Stepper.vue';
import StarRatingSelect from '@/themes/v1/components/StarRatingSelect.vue';
import YesNoSelector from '@/themes/v1/components/YesNoSelect.vue';
import ControlError from '@/themes/v1/components/ControlError.vue';
import ControlLabel from '@/themes/v1/components/ControlLabel.vue';
import { Feedback } from '@/modules/sts/feedback/send-feedback.model';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  components: {
    ControlLabel,
    ControlError,
    YesNoSelector,
    StarRatingSelect,
    Stepper,
  },
  directives: { TestLocator },
})
export default class FeedbackInput extends Vue {
  @Prop({ type: Object, required: true })
  options!: FeedbackOptions;

  @Prop({ type: Boolean, default: false })
  processing!: boolean;

  feedback: Feedback = {
    score: 0,
    optionalScores: {},
    message: '',
    solved: null as boolean | null,
  };
  step = 1;
  passed = 0;
  disableCancel = false;

  @Emit('submit')
  onSubmit() {
    return this.feedback;
  }

  @Emit('change')
  onChange() {
    return this.feedback;
  }

  @Emit('cancel')
  onCancel() {
    return true;
  }

  get stepsCount() {
    return this.options.criteria.length + 3;
  }

  get criteria() {
    return this.options.criteria[this.step - 2];
  }

  setOptionalScores(value: number) {
    this.feedback.optionalScores[this.criteria.key] = value;
    this.next();
  }

  setScore(value: number) {
    this.feedback.score = value;
    this.next();
  }

  setSolver(status: boolean) {
    this.feedback.solved = status;
    this.next();
  }

  next() {
    this.disableCancel = true;
    this.step += 1;
    this.setPassed(this.step);
    this.onChange();
  }

  softNext() {
    if (this.step >= this.passed || this.stepsCount === this.step) return;
    this.step++;
  }

  softPrev() {
    if (this.step === 1) return;
    this.step--;
  }

  setPassed(next: number) {
    if (this.passed > next) return;
    this.passed = next;
  }

  @Watch('feedback.message')
  onMessageChanged(newValue: string) {
    if (newValue.length > 3000) {
      this.feedback.message = this.feedback.message.slice(0, 3000);
    }
  }
}
