import { Guid } from '@/core/system';

export enum TicketStatus {
  New = 'new',
  Answered = 'answered',
  AwaitingReply = 'awaitingReply',
  Resolved = 'resolved',
  Rejected = 'rejected',
  NeedApproval = 'needApproval',
  Approved = 'approved',
  Revision = 'revision',
  InProgress = 'inProgress',
  ForReject = 'forReject',
  Created = 'created',
}

export interface UploadedFile {
  id: Guid;
  name: string;
  size: number;
  hasPreview: boolean;
  isImage: boolean;
  isDeleted: boolean;
}

export interface UploadFilesResponse {
  success: boolean;
  files: UploadedFile[];
}
