











































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  chatService,
  PlayerId,
  TicketChatMessage,
  TicketNumber,
} from '@/modules/sts/chat';
import Avatar from '@/themes/v1/views/sts/tickets/components/Avatar.vue';
import BgImage from '@/themes/v1/components/BgImage.vue';
import { UploadedFile } from '@/modules/sts/common.models';
import ImageViewer from '@/themes/v1/components/ImageViewer.vue';
import MessageAttachments from './MessageAttachments.vue';
import CopiedLinks from '@/themes/v1/directives/copied-links';
import ClickLinks from '@/themes/v1/directives/click-links';
import { NotificationsStore } from '@/themes/v1/stores/notifications.store';
import linkify from '@/themes/v1/fns/linkify';
import config from '@/env';
import { parsePath, pushWithLangAndPlatform } from '@/themes/v1/fns/router';
import copy from 'copy-text-to-clipboard';
import { LanguageCode } from '@/core/system';

@Component({
  components: { ImageViewer, BgImage, Avatar, MessageAttachments },
  directives: { ClickLinks, CopiedLinks },
})
export default class Message extends Vue {
  @Prop({ type: Object })
  message!: TicketChatMessage;

  @Prop({ type: String, required: true })
  playerId!: PlayerId;

  @Prop({ type: String, required: true })
  ticketNumber!: TicketNumber;

  @Prop({ type: String })
  language!: LanguageCode;

  @Prop({ type: Boolean })
  isLast!: boolean;

  @Emit()
  onRate(isHelpful: boolean) {
    return isHelpful;
  }

  $refs!: {
    imageViewer: InstanceType<typeof ImageViewer>;
  };

  get canRate() {
    return this.message.id != null && this.message.canBeRated;
  }

  get messageRated() {
    return this.message.messageWasHelpful ?? this.rated;
  }

  private notifications = getModule(NotificationsStore, this.$store);
  private rated: boolean | null = null;

  open(file: UploadedFile) {
    if (file.isImage) {
      const images = this.message.attachments.filter(
        (x) => x.isImage && !x.isDeleted
      );
      const position = images.indexOf(file);
      this.$refs.imageViewer.open(
        images.map((file) => ({
          src: chatService.getFilePreviewUrl(
            this.playerId,
            this.ticketNumber,
            file
          ),
          description: file.name,
        })),
        position
      );
    } else {
      const fileUrl = chatService.getFileUrl(
        this.playerId,
        this.ticketNumber,
        file,
        true
      );
      if (this.$layout().web) {
        window.open(fileUrl, '_blank');
      } else {
        copy(fileUrl);
        this.onCopiedLink();
      }
    }
  }

  get formattedMessage() {
    return linkify(this.message.text);
  }

  get copyLinksArgument() {
    return this.$layout().web ? 'disabled' : null;
  }

  onClickLink(link) {
    const src = link.dataset.href || link.href;
    const isRelative = /^\/.*/.test(src);

    if (isRelative) {
      this.$router.push(src);
      return;
    } else {
      const url = new URL(src);
      const isSameOrigin = url.host === window.location.host;
      const isRelatedOrigin = config.relatedDomains.includes(url.host);
      if (isSameOrigin || isRelatedOrigin) {
        const route = parsePath(url.pathname);
        if (route != null) {
          pushWithLangAndPlatform(this.$router, route.rest || '');
        } else {
          this.$router.push(url.pathname);
        }
        return;
      }
    }
    const isEmail = /^(?:mailto:)(.+)/.test(src);
    if (this.$layout().web) {
      window.open(src, '__blank');
    } else if (isEmail) {
      copy(src);
      this.onCopiedLink();
    } else {
      document.location.href = src;
    }
  }

  onCopiedLink() {
    this.notifications.notify({ text: this.$t('phrases.copied') as string });
  }

  rate(isHelpful: boolean) {
    this.rated = isHelpful;
    this.onRate(isHelpful);
  }
}
