




















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  directives: { TestLocator },
})
export default class Stepper extends Vue {
  @Prop({ type: Number, required: true })
  count!: number;

  @Prop({
    type: Number,
    default: 1,
    validator(value: number) {
      return value >= 1;
    },
  })
  current!: number;

  @Prop({ type: Number, default: 0 })
  passed!: number;

  @Emit('change')
  onChange(nextStep: number) {
    return nextStep;
  }

  next() {
    if (this.hasNext) {
      this.onChange(this.current + 1);
    }
  }

  prev() {
    if (this.hasPrev) {
      this.onChange(this.current - 1);
    }
  }

  setStep(step: number) {
    if (step > this.passed) return;
    this.onChange(step);
  }

  get hasPrev() {
    return this.current > 1;
  }

  get hasNext() {
    return this.current < this.count && this.passed > this.current;
  }
}
