var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"panel",staticClass:"reply-compact"},[_c('WarningPopover',{attrs:{"show":_vm.showFileSizeError || _vm.showFileError,"close-on-click":"","delay":5000},on:{"close":_vm.closeFileError}},[_vm._v(" "+_vm._s(_vm.showFileSizeError ? _vm.$t('forms.labels.fileLength') : _vm.$t('forms.errors.filesFormat'))+" ")]),(_vm.form.files != null && _vm.form.files.length > 0)?_c('div',{class:[
      'reply-compact-attachments',
      _vm.showAttachments && 'reply-compact-attachments--opened' ]},[_c('div',{staticClass:"reply-compact-attachments__title",on:{"click":function($event){return _vm.toggleShowAttachments()}}},[_vm._v(" "+_vm._s(_vm.$t('forms.files-summary', { count: _vm.form.files.length, size: _vm.filesSizeSummaryString, }))+" ")]),(_vm.showAttachments)?_c('div',{staticClass:"reply-compact-attachments__files"},_vm._l((_vm.form.files),function(file,n){return _c('FileView',{key:n,attrs:{"file":file,"loading":_vm.processing,"progress":_vm.filesStatuses[n]},on:{"remove":function($event){return _vm.removeFile(file)}}})}),1):_vm._e()]):_vm._e(),_c('GrayBox',{staticClass:"reply-compact__panel",attrs:{"loading":_vm.processing}},[_c('AttachInput',{directives:[{name:"test-locator",rawName:"v-test-locator",value:('input-file'),expression:"'input-file'"}],attrs:{"disabled":_vm.processing,"multiple":""},on:{"change":_vm.addFile}}),_c('ValidationObserver',{ref:"replyForm",staticClass:"reply-compact__form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('ValidationProvider',{attrs:{"tag":"div","rules":"required|min:3|max:3000","immediate":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var failedRules = ref.failedRules;
return [_c('textarea-autosize',{directives:[{name:"test-locator",rawName:"v-test-locator",value:('input-message'),expression:"'input-message'"}],ref:"messageInput",staticClass:"input",attrs:{"maxHeight":111,"rows":"1","placeholder":_vm.$t('forms.placeholders.message')},on:{"input":_vm.onMessageInput},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('WarningPopover',{attrs:{"show":failedRules.max != null}},[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('forms.errors.max-length-with-counter', {
                length: _vm.$nf(_vm.form.message.length),
              })
            )}})])]}}],null,true)})]}}])}),_c('button',{directives:[{name:"test-locator",rawName:"v-test-locator",value:('btn-send-reply'),expression:"'btn-send-reply'"}],staticClass:"button button--submit",attrs:{"type":"button","disabled":!_vm.isValid},on:{"click":_vm.sendReply}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }