























import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  model: {
    prop: 'value',
    event: 'change',
  },
  directives: { TestLocator },
})
export default class YesNoSelector extends Vue {
  @Prop({ default: null })
  value!: boolean | null;

  @Prop({ default: false })
  readonly!: boolean;

  @Emit('change')
  onChange(value: boolean) {
    return value;
  }
}
